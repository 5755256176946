export default [
  {
    title: "Dashboard",
    route: "dashboard",
    icon: "ArchiveIcon",
  },
  {
    title: "Pages",
    icon: "HomeIcon",
    children: [
      {
        title: "Home",
        route: "home",
      },
      {
        title: "About Us",
        route: "about-us",
      },
      {
        title: "Success Story",
        route: "success-story-page",
      },
      {
        title: "Course",
        route: "course-page",
      },
      {
        title: "Freelancing",
        route: "freelancing-page",
      },
      {
        title: "Our Facility",
        route: "our-facility-page",
      },
      {
        title: "Mentor",
        route: "teacher-page",
      },
      {
        title: "Student Feed Back",
        route: "student-feed-back-page",
      },
      {
        title: "Seminar",
        route: "seminar-page",
      },
      {
        title: "Job Placement",
        route: "job-placement-page",
      },
      {
        title: "FAQ",
        route: "faq-page",
      },
      {
        title: "Our Achievement",
        route: "our-achievement-page",
      },
      {
        title: "Our Gallery",
        route: "our-gallery-page",
      },
      {
        title: "Contact",
        route: "contact-page",
      },
      {
        title: "News",
        route: "news-page",
      },
    ],
  },
  {
    title: "Course",
    icon: "BookOpenIcon",
    children: [
      {
        title: "Department",
        route: "course-department",
      },
      {
        title: "Course List",
        route: "course",
      },
      {
        title: "Add Course",
        route: "course-create",
      },
      {
        title: "Option",
        route: "options",
      },
    ],
  },
  {
    title: "Success Story",
    icon: "CheckCircleIcon",
    children: [
      {
        title: "Success Story",
        route: "success_story",
      },
      {
        title: "Category",
        route: "success_story_category",
      },
    ],
  },
  {
    title: "CIT Achievement",
    route: "achievement",
    icon: "AwardIcon",
  },
  {
    title: "Student Review",
    icon: "HeadphonesIcon",
    children: [
      {
        title: "Review",
        route: "review",
      },
      {
        title: "Category",
        route: "review_category",
      },
    ],
  },
  {
    title: "Partners",
    icon: "CoffeeIcon",
    children: [
      {
        title: "Cit Partner",
        route: "cit-partner",
      },
      {
        title: "Career Placement",
        route: "career-placement-partner",
      },
    ],
  },
  {
    title: "CIT Milestone",
    route: "cit-milestone",
    icon: "ApertureIcon",
  },
  {
    title: "CIT Statement",
    route: "cit-statement",
    icon: "AlignLeftIcon",
  },
  {
    title: "CIT Photo Wall",
    route: "cit-photo-wall",
    icon: "CameraIcon",
  },
  {
    title: "Teachers",
    route: "teacher",
    icon: "UsersIcon",
  },
  {
    title: "Our Facility",
    route: "our-facility",
    icon: "CastIcon",
  },
  {
    title: "Seminar",
    route: "seminar",
    icon: "RadioIcon",
  },
  {
    title: "Job Plac. Overview",
    route: "job-placement-overview",
    icon: "ApertureIcon",
  },
  {
    title: "FAQ",
    route: "faq",
    icon: "MoveIcon",
  },
  {
    title: "Our Gallery",
    route: "our-gallery",
    icon: "CameraIcon",
  },
  {
    title: "Address",
    route: "address",
    icon: "MapIcon",
  },
  {
    title: "Header & Footer",
    route: "header-footer",
    icon: "ColumnsIcon",
  },
  {
    title: "Setting",
    icon: "SettingsIcon",
    children: [
      {
        title: "Users",
        route: "users",
      },
      {
        title: "Role",
        route: "roles",
      },
    ],
  },
  {
    title: "News",
    icon: "FilePlusIcon",
    children: [
      {
        title: "Category",
        route: "news-category",
      },
      {
        title: "Create News",
        route: "create-news",
      },
      {
        title: "News",
        route: "news",
      },
    ],
  },
];
